@font-face {
    font-family: "Nunito Sans";
    src: url("./css/fonts/NunitoSans-Regular.ttf");
    }
    @font-face {
        font-family: "Nunito Sans Bold";
        src: url("./css/fonts/NunitoSans-Bold.ttf");
        }

body {
    margin: 0;
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiPaper-rounded {
    max-height: 600px;
    overflow: auto;
}